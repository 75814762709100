import * as yup from "yup";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useEffect, useState } from "react";
import { useTenant } from "../../providers/tenant";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}

const EventSessionIntegrationForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const navigate = useNavigate();
  const { path } = useParams();
  const { t } = useTranslation();
  const { editEventSession } = useEventSession();
  const { tenant, getTenant } = useTenant();
  const { apiLoading } = useUser();
  const [SMSIntegration, setSMSIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.sms) ||
      (tenant && tenant.integrations.twilio ? "twilio" : "")
  );
  const [emailIntegration, setEmailIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.email) ||
      (tenant && tenant.integrations.postmark ? "postmark" : "")
  );
  const [voiceIntegration, setVoiceIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.audio) ||
      (tenant && tenant.integrations.twilioAudio ? "twilioAudio" : "")
  );
  const [whatsappIntegration, setWhatsappIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.whatsapp) ||
      (tenant && tenant.integrations.twilioWhatsapp ? "twilioWhatsapp" : "")
  );
  const [openAiIntegration, setOpenAiIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.ai) ||
      (tenant && tenant.integrations.openai ? "openai" : "")
  );
  const [shortsIntegration, setShortsIntegration] = useState(
    (eventSession?.integrations && eventSession?.integrations.shorts) ||
      (tenant && tenant.integrations.shorts ? "shorts" : "")
  );
  const [webhookWatchedLive, setWebhookWatchedLive] = useState(
    (eventSession?.integrations &&
      eventSession?.integrations.webhookWatchedLive) ||
      ""
  );
  const [webhookWatchedReplay, setWebhookWatchedReplay] = useState(
    (eventSession?.integrations &&
      eventSession?.integrations.webhookWatchedReplay) ||
      ""
  );
  const [webhookRegistered, setWebhookRegistered] = useState(
    (eventSession?.integrations &&
      eventSession?.integrations.webhookRegistered) ||
      ""
  );

  const handleChangeShortsIntegration = (event: any) => {
    setShortsIntegration(event.target.value);
  };

  const handleChangeOpenAiIntegration = (event: any) => {
    setOpenAiIntegration(event.target.value);
  };

  const handleChangeWhatsappIntegration = (event: any) => {
    setWhatsappIntegration(event.target.value);
  };

  const handleChangeVoiceIntegration = (event: any) => {
    setVoiceIntegration(event.target.value);
  };

  const handleChangeSMSIntegration = (event: any) => {
    setSMSIntegration(event.target.value);
  };

  const handleChangeEmailIntegration = (event: any) => {
    setEmailIntegration(event.target.value);
  };

  const handleChangeWebhookWatchedLive = (event: any) => {
    setWebhookWatchedLive(event.target.value);
  };

  const handleChangeWebhookWatchedReplay = (event: any) => {
    setWebhookWatchedReplay(event.target.value);
  };

  const handleChangeWebhookRegistered = (event: any) => {
    setWebhookRegistered(event.target.value);
  };

  const onSubmit = async (data: any) => {
    try {
      const updatedEventSession = {
        integrations: {
          email: emailIntegration,
          whatsapp: whatsappIntegration,
          audio: voiceIntegration,
          sms: SMSIntegration,
          shorts: shortsIntegration,
          ai: openAiIntegration,
          webhookWatchedLive,
          webhookWatchedReplay,
          webhookRegistered,
        },
      };
      if (!eventSession) {
        navigate(`/${path}/events`);
        return;
      }
      const response = await editEventSession(
        eventSession.id,
        updatedEventSession
      );
      setEventSession(response);
      handleComplete();
    } catch (error) {
      console.error("Failed to update event session:", error);
    }
  };

  useEffect(() => {
    if (!tenant) {
      getTenant();
    }
  }, [tenant, getTenant]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="integrationsDiv">
          <h3>Notification integrations</h3>
          <div>
            <FormControl>
              <InputLabel id="email-integration-label">
                Email Integration
              </InputLabel>
              <Select
                labelId="email-integration-label"
                id="email-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={emailIntegration}
                label="Email Integration"
                {...register("emailIntegration")}
                onChange={handleChangeEmailIntegration}
              >
                {tenant && tenant.integrations.postmark ? (
                  <MenuItem value={"postmark"}>Postmark</MenuItem>
                ) : null}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="whatsapp-integration-label">
                Whatsapp Integration
              </InputLabel>
              <Select
                labelId="whatsapp-integration-label"
                id="whatsapp-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={whatsappIntegration}
                label="Whatsapp Integration"
                {...register("whatsappIntegration")}
                onChange={handleChangeWhatsappIntegration}
              >
                {tenant && tenant.integrations.twilioWhatsapp ? (
                  <MenuItem value={"twilioWhatsapp"}>Twilio</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <InputLabel id="sms-integration-label">
                SMS Integration
              </InputLabel>
              <Select
                labelId="sms-integration-label"
                id="sms-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={SMSIntegration}
                label="SMS Integration"
                {...register("SMSIntegration")}
                onChange={handleChangeSMSIntegration}
              >
                {tenant && tenant.integrations.twilio ? (
                  <MenuItem value={"twilio"}>Twilio</MenuItem>
                ) : null}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="voice-integration-label">
                Voice Integration
              </InputLabel>
              <Select
                labelId="voice-integration-label"
                id="voice-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={voiceIntegration}
                label="Voice Integration"
                {...register("voiceIntegration")}
                onChange={handleChangeVoiceIntegration}
              >
                {tenant && tenant.integrations.twilioAudio ? (
                  <MenuItem value={"twilioAudio"}>Twilio</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="integrationsDiv">
          <h3>Other integrations</h3>
          <div>
            <FormControl>
              <InputLabel id="ai-integration-label">AI Integration</InputLabel>
              <Select
                labelId="ai-integration-label"
                id="ai-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={openAiIntegration}
                label="AI Integration"
                {...register("openAiIntegration")}
                onChange={handleChangeOpenAiIntegration}
              >
                {tenant && tenant.integrations.openai ? (
                  <MenuItem value={"openai"}>OpenAI</MenuItem>
                ) : null}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="shorts-integration-label">
                Short Integration
              </InputLabel>
              <Select
                labelId="shorts-integration-label"
                id="shorts-integration-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={shortsIntegration}
                label="Short Integration"
                {...register("shortsIntegration")}
                onChange={handleChangeShortsIntegration}
              >
                {tenant && tenant.integrations.shorts ? (
                  <MenuItem value={"shorts"}>Short</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="integrationsDiv">
          <h3>Webhook integrations</h3>
          <div>
            <FormControl>
              <InputLabel id="webhook-watched-live-label" shrink={true}>
                Webhook Watched Live
              </InputLabel>
              <Select
                labelId="webhook-watched-live-label"
                id="webhook-watched-live-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={webhookWatchedLive}
                label="Webhook Watched Live"
                {...register("webhookWatchedLive")}
                onChange={handleChangeWebhookWatchedLive}
                displayEmpty
                renderValue={(value) => (value === "" ? "None" : value)}
              >
                <MenuItem value="">None</MenuItem>
                {tenant &&
                  tenant.integrations.webhooks &&
                  tenant.integrations.webhooks.map((webhook: any) => (
                    <MenuItem key={webhook.link} value={webhook.name}>
                      {webhook.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="webhook-watched-replay-label" shrink={true}>
                Webhook Watched Replay
              </InputLabel>
              <Select
                labelId="webhook-watched-replay-label"
                id="webhook-watched-replay-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={webhookWatchedReplay}
                label="Webhook Watched Replay"
                {...register("webhookWatchedReplay")}
                onChange={handleChangeWebhookWatchedReplay}
                displayEmpty
                renderValue={(value) => (value === "" ? "None" : value)}
              >
                <MenuItem value="">None</MenuItem>
                {tenant &&
                  tenant.integrations.webhooks &&
                  tenant.integrations.webhooks.map((webhook: any) => (
                    <MenuItem key={webhook.link} value={webhook.name}>
                      {webhook.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="webhook-registered-label" shrink={true}>
                Webhook Registered
              </InputLabel>
              <Select
                labelId="webhook-registered-label"
                id="webhook-registered-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: 442,
                }}
                value={webhookRegistered}
                label="Webhook Registered"
                {...register("webhookRegistered")}
                onChange={handleChangeWebhookRegistered}
                displayEmpty
                renderValue={(value) => (value === "" ? "None" : value)}
              >
                <MenuItem value="">None</MenuItem>
                {tenant &&
                  tenant.integrations.webhooks &&
                  tenant.integrations.webhooks.map((webhook: any) => (
                    <MenuItem key={webhook.link} value={webhook.name}>
                      {webhook.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            onClick={handleBack}
            disabled={apiLoading}
            sx={{ mr: 1, borderRadius: "25px" }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            disabled={apiLoading}
            variant="contained"
            sx={{ mr: 1, borderRadius: "25px" }}
          >
            Save & Next
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default EventSessionIntegrationForm;
