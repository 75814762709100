import { Container } from "./styles";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { default as timezoneDayjs } from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEventSession } from "../../providers/eventSessions";
import {
  IEventSession,
  ISessionSchedule,
} from "../../providers/eventSessions/eventsSessions.interface";
import { useUser } from "../../providers/users/index";
dayjs.extend(utc);
dayjs.extend(timezoneDayjs);

interface IProps {
  eventSession: IEventSession;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession>>;
  handleComplete: () => void;
  handleBack: () => void;
}
type EventType = "on" | "every" | "dynamic";

const EventSessionScheduleForm = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const { editEventSession } = useEventSession();
  const { apiLoading } = useUser();
  const navigate = useNavigate();
  const { path, event_id } = useParams();
  const dateNow = new Date();
  dateNow.setMilliseconds(0);
  dateNow.setSeconds(0);

  const initialSessions = eventSession.sessionSchedule?.length
    ? eventSession.sessionSchedule.map((schedule) => ({
        recurrency:
          schedule.recurrency ??
          (eventSession.sessionNumber === 1 ? "on" : "dynamic"),
        day: schedule.day ?? "Monday",
        timezone: schedule.timezone ?? "America/New_York",
        onDate: dayjs.tz(
          schedule.date ?? null,
          schedule.timezone ?? "America/New_York"
        ),
        everyTime: dayjs(
          schedule.startTime
            ? createDateFromTimeString(schedule.startTime)
            : null
        ).tz(schedule.timezone ?? "America/New_York", true),
        hours: Math.floor((schedule.hoursAfter ?? 0) / 3600000),
        minutes: Math.floor((schedule.hoursAfter ?? 0 % 3600000) / 60000),
        allowLateAttendance: schedule.allowLateAttendance ?? false,
        blockShortTerms: schedule.blockShortTerms ?? "disabled",
        displayedSessions: eventSession.displayedSessions ?? 1,
      }))
    : [
        {
          recurrency:
            eventSession.sessionSchedule?.recurrency ??
            (eventSession.sessionNumber === 1 ? "on" : "dynamic"),
          day: eventSession.sessionSchedule?.day ?? "Monday",
          timezone:
            eventSession.sessionSchedule?.timezone ?? "America/New_York",
          onDate: dayjs.tz(
            eventSession.sessionSchedule?.date ?? null,
            eventSession.sessionSchedule?.timezone ?? "America/New_York"
          ),
          everyTime: dayjs(
            eventSession.sessionSchedule?.startTime
              ? createDateFromTimeString(
                  eventSession.sessionSchedule?.startTime
                )
              : null
          ).tz(
            eventSession.sessionSchedule?.timezone ?? "America/New_York",
            true
          ),
          hours: Math.floor(
            (eventSession.sessionSchedule?.hoursAfter ?? 0) / 3600000
          ),
          minutes: Math.floor(
            ((eventSession.sessionSchedule?.hoursAfter ?? 0) % 3600000) / 60000
          ),
          allowLateAttendance:
            eventSession.sessionSchedule?.allowLateAttendance ?? false,
          blockShortTerms:
            eventSession.sessionSchedule?.blockShortTerms ?? "disabled",
          displayedSessions: eventSession.displayedSessions ?? 1,
        },
      ];

  const [sessions, setSessions] = useState(initialSessions);
  const handleAddSession = () => {
    setSessions([
      ...sessions,
      {
        recurrency: "on",
        day: "Monday",
        timezone: "America/New_York",
        onDate: dayjs.tz(null, "America/New_York"),
        everyTime: dayjs.tz(null, "America/New_York"),
        hours: 0,
        minutes: 0,
        allowLateAttendance: false,
        blockShortTerms: "disabled",
        displayedSessions: 1,
      },
    ]);
  };

  const handleRemoveSession = (index: number) => {
    const newSessions = [...sessions];
    newSessions.splice(index, 1);
    setSessions(newSessions);
  };

  const handleSessionChange = (index: number, field: string, value: any) => {
    const newSessions = [...sessions];
    newSessions[index][field] = value;
    setSessions(newSessions);
  };

  const onSubmit = async (data: any) => {
    const sessionSchedule = sessions.map((session: any) => {
      const data: any = {
        allowLateAttendance: session.allowLateAttendance,
        timezone: session.timezone,
      };

      if (session.recurrency === "on") {
        if (!session.onDate || !session.onDate.get("date")) {
          throw Error("You need to select a date");
        }
        data.recurrency = "on";
        data.date = session.onDate.tz(session.timezone, true).valueOf();
      } else if (session.recurrency === "every") {
        if (!session.everyTime || !session.everyTime.get("date")) {
          throw Error("You need to select a date");
          return;
        }
        const timeZoned = session.everyTime.tz(session.timezone, true);
        data.recurrency = "every";
        data.day = session.day;
        data.startTime = timeZoned.format("h:mm A");
      } else {
        data.recurrency = "dynamic";
        data.hoursAfter = session.hours * 3600000 + session.minutes * 60000;
      }
      return data;
    });

    if (!eventSession) {
      toast.error("Something got wrong, please try again.");
      navigate(`/${path}/events/${event_id}/eventsessions`);
      return;
    }

    const response = await editEventSession(eventSession.id, {
      displayedSessions: sessions[0].displayedSessions,
      blockShortTerms: sessions[0].blockShortTerms,
      justInTime: data.justInTime ? true : false,
      justInTimeInterval: Number(data.justInTimeInterval),
      sessionSchedule:
        eventSession.sessionNumber !== 1 ? sessionSchedule[0] : sessionSchedule,
    });
    if (response) {
      setEventSession(response);
      handleComplete();
    }
  };

  const timezoneList = Intl.supportedValuesOf("timeZone");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    dayjs.tz.setDefault(sessions[0].timezone);
  }, [sessions]);

  const datePicker = (session, index) => ({
    on: {
      datePicker: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            label="Select date"
            value={session.onDate}
            disablePast={true}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
              ml: 2,
            }}
            onChange={(newValue) => {
              const date = dayjs.tz(newValue, session.timezone);
              date.second(0);
              handleSessionChange(index, "onDate", date);
            }}
          />
        </LocalizationProvider>
      ),
      timePicker: <></>,
      timezonePicker: (
        <FormControl>
          <InputLabel id="timezone-label">Timezone</InputLabel>
          <Select
            labelId="timezone-label"
            id="demo-simple-select"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            value={session.timezone}
            label="timezone"
            {...register("timezone")}
            onChange={(event) =>
              handleSessionChange(index, "timezone", event.target.value)
            }
          >
            {timezoneList.map((e: string) => (
              <MenuItem value={e} key={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },

    every: {
      datePicker: (
        <FormControl>
          <InputLabel id="timezone-label">Day</InputLabel>
          <Select
            labelId="timezone-label"
            id="demo-simple-select"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            value={session.day}
            label="day"
            onChange={(event) =>
              handleSessionChange(index, "day", event.target.value)
            }
          >
            <MenuItem value={"Monday"}>Monday</MenuItem>
            <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
            <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
            <MenuItem value={"Thursday"}>Thursday</MenuItem>
            <MenuItem value={"Friday"}>Friday</MenuItem>
            <MenuItem value={"Saturday"}>Saturday</MenuItem>
            <MenuItem value={"Sunday"}>Sunday</MenuItem>
          </Select>
        </FormControl>
      ),
      timePicker: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Select time"
            value={session.everyTime}
            onChange={(newValue) => {
              newValue.second(0);
              handleSessionChange(index, "everyTime", newValue);
            }}
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
              ml: 2,
            }}
          />
        </LocalizationProvider>
      ),
      timezonePicker: (
        <FormControl>
          <InputLabel id="timezone-label">Timezone</InputLabel>
          <Select
            labelId="timezone-label"
            id="demo-simple-select"
            sx={{
              boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
              "& fieldset": { border: "none" },
              backgroundColor: "#FFF",
            }}
            value={session.timezone}
            label="timezone"
            {...register("timezone")}
            onChange={(event) =>
              handleSessionChange(index, "timezone", event.target.value)
            }
          >
            {timezoneList.map((e: string) => (
              <MenuItem value={e} key={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    dynamic: {
      datePicker: (
        <TextField
          id="standard-hour"
          error={errors.hours === undefined ? false : true}
          helperText={errors.hours?.message?.toString()}
          label="hours"
          variant="filled"
          type="number"
          required
          value={session.hours}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSessionChange(index, "hours", Number(event.target.value));
          }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
        />
      ),
      timePicker: (
        <TextField
          id="standard-minutes"
          error={errors.minutes === undefined ? false : true}
          helperText={errors.minutes?.message?.toString()}
          label="minutes"
          type="number"
          variant="filled"
          required
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
            ml: 1,
            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          value={session.minutes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSessionChange(index, "minutes", Number(event.target.value));
          }}
        />
      ),
      timezonePicker: "After start of session one",
    },
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Schedule settings</h3>
        {sessions.map((session, index) => (
          <div key={index} className="scheduleDiv">
            <FormControl>
              <InputLabel id="recurrency-label">Recurrency</InputLabel>
              <Select
                labelId="recurrency-label"
                id="demo-simple-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  width: "200px",
                }}
                value={session.recurrency}
                label={"Recurrency"}
                {...register("recurrency")}
                onChange={(event) =>
                  handleSessionChange(index, "recurrency", event.target.value)
                }
              >
                {eventSession.sessionNumber === 1 && (
                  <MenuItem value={"on"}>On</MenuItem>
                )}
                {eventSession.sessionNumber === 1 && (
                  <MenuItem value={"every"}>Every</MenuItem>
                )}
                {eventSession.sessionNumber !== 1 && (
                  <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                )}
              </Select>
            </FormControl>

            <div>
              {datePicker(session, index)[session.recurrency].datePicker}
            </div>
            <div>
              {datePicker(session, index)[session.recurrency].timePicker}
            </div>
            <div>
              {datePicker(session, index)[session.recurrency].timezonePicker}
            </div>

            {eventSession.sessionNumber === 1 && index !== 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemoveSession(index)}
                sx={{ ml: 2 }}
              >
                Remove Session
              </Button>
            )}
          </div>
        ))}

        {eventSession.sessionNumber === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSession}
            sx={{ mt: 2, maxWidth: 150 }}
          >
            Add Session
          </Button>
        )}

        <div className="advancedSettings">
          <h3>Advanced settings</h3>
          <div>
            <div>
              {" "}
              <span>Allow late attendance</span>
              <Switch
                checked={sessions[0].allowLateAttendance}
                onChange={(event) =>
                  handleSessionChange(
                    0,
                    "allowLateAttendance",
                    event.target.checked
                  )
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div>
            <div>
              <FormControl sx={{ mt: 2, minWidth: 200 }}>
                <InputLabel id="just-in-time-label">Just in time</InputLabel>
                <Select
                  labelId="just-in-time-label"
                  id="just-in-time"
                  defaultValue={eventSession?.justInTime || false}
                  {...register("justInTime")}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
                    "& fieldset": { border: "none" },
                    backgroundColor: "#FFF",
                  }}
                >
                  <MenuItem value={false}>Disabled</MenuItem>
                  <MenuItem value={true}>Enabled</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 2, ml: 2, minWidth: 150 }}>
                <InputLabel id="just-in-time-interval-label">
                  Just in time interval
                </InputLabel>
                <Select
                  labelId="just-in-time-interval-label"
                  id="just-in-time-interval"
                  defaultValue={eventSession?.justInTimeInterval || 15}
                  {...register("justInTimeInterval")}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
                    "& fieldset": { border: "none" },
                    backgroundColor: "#FFF",
                  }}
                >
                  {/* <MenuItem value={0o0}>00</MenuItem> */}
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <div>
              <span>Displayed sessions</span>

              <Select
                labelId="recurrency-label"
                id="demo-simple-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  ml: 2,
                }}
                value={sessions[0].displayedSessions}
                onChange={(event) =>
                  handleSessionChange(
                    0,
                    "displayedSessions",
                    event.target.value
                  )
                }
              >
                <MenuItem value={1}>Display only the next</MenuItem>
                <MenuItem value={2}>Display the next 2 sessions</MenuItem>
                <MenuItem value={3}>Display the next 3 sessions</MenuItem>
              </Select>
            </div>
            <div>
              <span className="shortTerms">Block short terms</span>

              <Select
                labelId="recurrency-label"
                id="demo-simple-select"
                sx={{
                  boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FFF",
                  ml: 2,
                }}
                value={sessions[0].blockShortTerms}
                onChange={(event) =>
                  handleSessionChange(0, "blockShortTerms", event.target.value)
                }
              >
                <MenuItem value={"disabled"}>Disabled</MenuItem>
                <MenuItem value={"enabled"}>Enabled</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1, borderRadius: "25px" }}
            disabled={apiLoading}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 1, borderRadius: "25px" }}
            disabled={apiLoading}
          >
            Save & Next
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default EventSessionScheduleForm;

function createDateFromTimeString(timeString: string) {
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  let adjustedHours = hours;
  if (period === "PM" && hours !== 12) {
    adjustedHours += 12;
  } else if (period === "AM" && hours === 12) {
    adjustedHours = 0;
  }
  const currentDate = new Date();
  const newDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    adjustedHours,
    minutes
  );
  return newDate;
}
