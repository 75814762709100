import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services";
import { useUser } from "../users";
import { useLocation, useNavigate } from "react-router-dom";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreateEmailNotification,
  ICreateEventSession,
  ICreateLiveContent,
  ICreateSMSNotification,
  IEventSession,
  IUpdateEventSession,
} from "./eventsSessions.interface";
import { useTenant } from "../tenant";
import { IWhatsappTemplate } from "../notifications/notifications.interface";

export const EventSessionContext = createContext<any | null>(null);

export const EventSessionProvider = ({ children }: IProviderProps) => {
  const [allEventSessions, setAllEventSessions] = useState<
    IEventSession[] | null
  >(null);
  const navigate = useNavigate();
  const { token, setApiLoading, setPageMounting } = useUser();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const getAllEventSessions = async (mountPage: boolean = true) => {
    if (mountPage) setPageMounting(true);
    await api
      .get(`eventsessions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllEventSessions(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        setPageMounting(false);
        console.log(err.response.data.message);
      });
  };
  const createEventSession = async (data: ICreateEventSession) => {
    setApiLoading(true);

    const session = await api
      .post("eventsessions", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // getAllEventSessions(res.data.eventId);
        if (allEventSessions) {
          setAllEventSessions([...allEventSessions, res.data]);
        } else {
          setAllEventSessions([res.data]);
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
    return session;
  };

  const cloneEventSession = async (id: string) => {
    setApiLoading(true);

    await api
      .post(
        "eventsessions/clone/" + id,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        getAllEventSessions(false);
        setApiLoading(false);
        navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
      });
  };

  const editEventSession = async (id: string, data: IUpdateEventSession) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    const session = await api
      .patch(`eventsessions/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/eventsessions/${id}/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              if (allEventSessions) {
                setAllEventSessions(
                  allEventSessions.map((i) =>
                    i.id === res.data.id
                      ? {
                          ...res.data,
                          whatsappNotifications: i.whatsappNotifications,
                        }
                      : i
                  )
                );
              }
              setApiLoading(false);
            })
            .catch((err) => {
              setApiLoading(false);
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            });
        } else {
          if (allEventSessions) {
            setAllEventSessions(
              allEventSessions.map((i) =>
                i.id === res.data.id
                  ? {
                      ...res.data,
                      whatsappNotifications: i.whatsappNotifications,
                    }
                  : i
              )
            );
          }
        }
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
    return session;
  };

  const deleteEventSession = async (id: string, eventId: string) => {
    setApiLoading(true);
    await api
      .delete(`eventsessions/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        if (allEventSessions) {
          setAllEventSessions(allEventSessions.filter((e) => e.id !== id));
        }
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const createLiveContent = async (data: any) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    const session = await api
      .post("livecontents", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/livecontents/${res.data.id}/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              if (allEventSessions) {
                setAllEventSessions(
                  allEventSessions.map((i: any) => {
                    if (i.id === res.data.sessionId) {
                      i.liveContents = [...i.liveContents, res.data];
                      return i;
                    } else {
                      return i;
                    }
                  })
                );
              }
              setApiLoading(false);
            })
            .catch((err) => {
              setApiLoading(false);
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            });
        } else {
          if (allEventSessions) {
            setAllEventSessions(
              allEventSessions.map((i: any) => {
                if (i.id === res.data.sessionId) {
                  i.liveContents = [...i.liveContents, res.data];
                  return i;
                } else {
                  return i;
                }
              })
            );
          }
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const addProgrammedChats = async (
    session_id: string,
    file: File,
    event_id: string
  ) => {
    setApiLoading(true);
    const dataFile = new FormData();
    dataFile.append("file", file);

    const added = await api
      .post(`/programmedchats/csv/${session_id}`, dataFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        gettAllProgrammedChats(session_id);
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data);
        return false;
      });
  };

  const getRegistrantById = async (id: string) => {
    setApiLoading(true);
    const registrant = await api
      .get(`registrants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
    return registrant;
  };

  const createEmailNotification = async (data: ICreateEmailNotification) => {
    setApiLoading(true);

    const session = await api
      .post("emailnotifications", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((i: any) => {
              if (i.id === res.data.sessionId) {
                i.emailNotifications = [...i.emailNotifications, res.data];
                return i;
              } else {
                return i;
              }
            })
          );
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const createSMSNotification = async (data: ICreateSMSNotification) => {
    setApiLoading(true);

    const session = await api
      .post("smsnotifications", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((i: any) => {
              if (i.id === res.data.sessionId) {
                i.SMSNotifications = [...i.SMSNotifications, res.data];
                return i;
              } else {
                return i;
              }
            })
          );
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const createAudioNotification = async (data: any) => {
    setApiLoading(true);

    const session = await api
      .post("audionotifications", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((i: any) => {
              if (i.id === res.data.sessionId) {
                i.audioNotifications = [...i.audioNotifications, res.data];
                return i;
              } else {
                return i;
              }
            })
          );
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const createWhatsappNotification = async (
    data: any,
    template: IWhatsappTemplate
  ) => {
    setApiLoading(true);

    const session = await api
      .post("whatsappnotifications", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((i: any) => {
              if (i.id === res.data.sessionId) {
                i.whatsappNotifications = [
                  ...i.whatsappNotifications,

                  { ...res.data, template },
                ];
                return i;
              } else {
                return i;
              }
            })
          );
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const createProgrammedChats = async (data: any) => {
    setApiLoading(true);

    const session = await api
      .post("programmedchats", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((i: any) => {
              if (i.id === res.data.sessionId) {
                i.programmedChats = [...i.programmedChats, res.data];
                return i;
              } else {
                return i;
              }
            })
          );
        }
        setApiLoading(false);
        return res.data;
        // navigate(`/${path}/events/${res.data.eventId}/eventsessions`);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err);
      });
    return session;
  };

  const updateProgrammedChat = async (id: string, data: any) => {
    setApiLoading(true);

    const session = await api
      .patch("programmedchats/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.programmedChats =
                session.programmedChats &&
                session.programmedChats.map((chat: any) => {
                  if (chat.id === res.data.id) {
                    return res.data;
                  } else {
                    return chat;
                  }
                });
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })

      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
    return session;
  };
  const gettAllProgrammedChats = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .get("programmedchats/sessions/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              if (session.id === id) {
                session.programmedChats = res.data;
                return session;
              }
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })

      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
    return session;
  };

  const deleteProgrammedChat = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("programmedchats/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.programmedChats =
                session.programmedChats &&
                session.programmedChats.filter((chat: any) => {
                  return chat.id !== id;
                });
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const deleteMultipleProgrammedChats = (chatIds: string[]) => {
    setApiLoading(true);

    api
      .delete("/programmedchats/multiple", {
        headers: { Authorization: `Bearer ${token}` },
        data: { programmedChats: chatIds },
      })
      .then(() => {
        setAllEventSessions((prevSessions: any) => {
          if (prevSessions) {
            return prevSessions.map((session: any) => ({
              ...session,
              programmedChats: session.programmedChats
                ? session.programmedChats.filter(
                    (chat: any) => !chatIds.includes(chat.id)
                  )
                : [],
            }));
          }
          return prevSessions;
        });
        setApiLoading(false);
        toast.success("Chats deleted with success");
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response?.data?.message || "Error deleting chats");
        toast.error(err.response?.data?.message || "Error deleting chats");
      });
  };
  const updateMultipleProgrammedChats = (
    chatIds: string[],
    approved: boolean
  ) => {
    setApiLoading(true);

    api
      .patch(
        "/programmedchats/multiple",
        {
          programmedChats: chatIds,
          approved: approved,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setAllEventSessions((prevSessions: any) => {
          if (prevSessions) {
            return prevSessions.map((session: any) => ({
              ...session,
              programmedChats: session.programmedChats
                ? session.programmedChats.map((chat: any) =>
                    chatIds.includes(chat.id)
                      ? { ...chat, approved: approved }
                      : chat
                  )
                : [],
            }));
          }
          return prevSessions;
        });
        setApiLoading(false);
        toast.success("Chats updated with success");
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response?.data?.message || "Error updating chats");
        toast.error(err.response?.data?.message || "Error updating chats");
      });
  };

  const generateNamesToProgrammedChats = (
    chatIds: string[],
    listName: string,
    sessionId: string
  ) => {
    setApiLoading(true);

    api
      .patch(
        "/programmedchats/names",
        {
          programmedChats: chatIds,
          listName,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        gettAllProgrammedChats(sessionId);

        setApiLoading(false);
        toast.success("Chat names updated successfully");
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response?.data?.message || "Error updating chat names");
        toast.error(err.response?.data?.message || "Error updating chat names");
      });
  };
  const deleteAllProgrammedChats = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("programmedchats/all/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.programmedChats = [];
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const updateLiveContent = async (id: string, data: any) => {
    setApiLoading(true);
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    const session = await api
      .patch("livecontents/" + id, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (dataFile) {
          api
            .post(`/livecontents/${id}/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              if (allEventSessions) {
                setAllEventSessions(
                  allEventSessions.map((session: any) => {
                    session.liveContents = session.liveContents.map(
                      (chat: any) => {
                        if (chat.id === res.data.id) {
                          return res.data;
                        } else {
                          return chat;
                        }
                      }
                    );
                    return session;
                  })
                );
              }
              setApiLoading(false);
            })
            .catch((err) => {
              setApiLoading(false);
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            });
        } else {
          if (allEventSessions) {
            setAllEventSessions(
              allEventSessions.map((session: any) => {
                session.liveContents = session.liveContents.map(
                  (liveContent: any) => {
                    if (liveContent.id === res.data.id) {
                      return res.data;
                    } else {
                      return liveContent;
                    }
                  }
                );
                return session;
              })
            );
          }
        }
        setApiLoading(false);
        return res.data;
      })

      .catch((err) => {
        setApiLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
    return session;
  };

  const deleteLiveContent = async (id: string) => {
    setApiLoading(true);

    const session = await api
      .delete("livecontents/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (allEventSessions) {
          setAllEventSessions(
            allEventSessions.map((session: any) => {
              session.liveContents = session.liveContents.filter(
                (contents: any) => {
                  return contents.id !== id;
                }
              );
              return session;
            })
          );
        }
        setApiLoading(false);
        return res.data;
      });
  };

  return (
    <EventSessionContext.Provider
      value={{
        allEventSessions,
        addProgrammedChats,
        cloneEventSession,
        setAllEventSessions,
        createEventSession,
        editEventSession,
        deleteEventSession,
        getAllEventSessions,
        createLiveContent,
        getRegistrantById,
        createSMSNotification,
        createEmailNotification,
        createAudioNotification,
        createWhatsappNotification,
        createProgrammedChats,
        deleteProgrammedChat,
        updateProgrammedChat,
        updateMultipleProgrammedChats,
        updateLiveContent,
        deleteLiveContent,
        deleteAllProgrammedChats,
        gettAllProgrammedChats,
        deleteMultipleProgrammedChats,
        generateNamesToProgrammedChats,
      }}
    >
      {children}
    </EventSessionContext.Provider>
  );
};

export const useEventSession = () => useContext(EventSessionContext);
